export default [{
  path: '/list_deposits',
  name: 'listDeposits',
  component: () => import('@/views/DepositsAdmin.vue'),
  meta: {
    page_slug: 'approve_deposits',
    breadcrumb: [{
      page_slug: 'approve_deposits',
      active: true,
    }],
  },
},
{
  path: '/list_saques',
  name: 'listSaques',
  component: () => import('@/views/WithdrawAdmin.vue'),
  meta: {
    page_slug: 'approve_withdraws',
    breadcrumb: [{
      page_slug: 'approve_withdraws',
      active: true,
    }],
  },
},
{
  path: '/users',
  name: 'users',
  component: () => import('@/views/Users.vue'),
  meta: {
    page_slug: 'users',
    breadcrumb: [{
      page_slug: 'users',
      active: true,
    }],
  },
},
{
  path: '/performance',
  name: 'performance',
  component: () => import('@/views/Performance.vue'),
  meta: {
    page_slug: 'performance',
    breadcrumb: [{
      page_slug: 'performance',
      active: true,
    }],
  },
},
]
