import store from '@/store'

/**
 * Middleware que verifica se usuario esta logado.
 */
export default function authMiddleware(router) {
  router.beforeEach((to, from, next) => {
    // check for the routes that have `requiresAuth: true`
    if (to.matched.some(record => record.meta.auth)) {
      if (!store.getters['user/token']) {
        return next({
          path: '/',
        })
      }
      return next()
    }
    return next()
  })
}
