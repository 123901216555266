export default [{
  path: '/login',
  name: 'login',
  component: () => import('@/views/Login.vue'),
  meta: {
    layout: 'full',
  },
},
{
  path: '/register',
  name: 'register',
  component: () => import('@/views/Register.vue'),
  meta: {
    layout: 'full',
    resource: 'Auth',
    redirectIfLoggedIn: true,
  },
},
{
  path: '/forgot-password',
  name: 'forgot-password',
  component: () => import('@/views/ForgotPassword.vue'),
  meta: {
    layout: 'full',
    resource: 'Auth',
    redirectIfLoggedIn: true,
  },
},
{
  path: '/password-reset/:token',
  name: 'password-reset',
  component: () => import('@/views/PasswordReset.vue'),
  meta: {
    layout: 'full',
    resource: 'Auth',
    redirectIfLoggedIn: true,
  },
},
]
