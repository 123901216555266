// axios
import axios from 'axios'
import store from '@/store'

// import router from './router'
const instance = axios.create()

instance.interceptors.request.use(config => {
  const token = store.getters['users/token']
  const configTo = config

  if (token) {
    // eslint-disable-next-line dot-notation
    configTo.headers['Authorization'] = `Bearer ${token}`
  }

  // eslint-disable-next-line dot-notation
  configTo.headers['Accept'] = 'application/json'
  configTo.headers['Content-Type'] = 'application/json'
  configTo.headers['X-Requested-With'] = 'XMLHttpRequest'

  // Precisa que data seja um objeto para enviar o Content-Type
  if (!config.data) {
    configTo.data = {}
  }

  // config.baseURL = process.env.API_HOST
  configTo.timeout = process.env.VUE_APP_API_TIMEOUT

  return configTo
})

instance.interceptors.response.use(
  response => response,

  err => {
    const { response } = err

    if (response.status) {
      if (response.status === 422) {
        if (response.data.message !== '') {
          // eslint-disable-next-line no-alert
          // console.log(response.data.data.Message)
        } else {
          // let errors = Object.values(response.data.errors)
          // errors = errors.flat()

          // errors.forEach(element => {
          //   // eslint-disable-next-line no-alert
          //   // alert(element)
          //   // console.log(element)
          // })
        }

        // Erro ja foi tratado nao é necessário retornar o reject
        // return
      }

      if (response.status === 500) {
        // eslint-disable-next-line no-alert
        alert('Error loading server content')

        // Erro ja foi tratado nao é necessário retornar o reject
        return
      }

      // if (response.status === 401 || response.status === 403) {
      //   store.dispatch('auth/clear')

      //   router.push({
      //     name: 'login',
      //   })
      // }

      if (response.status === 404) {
        if (typeof response.data.errors !== 'undefined') {
          let errors = Object.values(response.data.errors)
          errors = errors.flat()

          errors.forEach(element => {
            // eslint-disable-next-line no-alert
            alert(element)
          })

          return
        }

        // eslint-disable-next-line no-alert
        alert('Not Found!')

        // Erro ja foi tratado nao é necessário retornar o reject
        return
      }
    }

    // eslint-disable-next-line consistent-return
    return Promise.reject(err)
  },
)

export default instance
