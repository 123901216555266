import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import VueSweetalert2 from 'vue-sweetalert2'
import VueMoment from 'vue-moment'

// eslint-disable-next-line import/no-extraneous-dependencies
import 'sweetalert2/dist/sweetalert2.min.css'

Vue.component(FeatherIcon.name, FeatherIcon)
Vue.use(VueSweetalert2)

// eslint-disable-next-line import/no-extraneous-dependencies
const moment = require('moment')
// eslint-disable-next-line import/no-extraneous-dependencies
require('moment/locale/pt-br')

Vue.use(VueMoment, {
  moment,
})
