/**
 * Este arquivo importa todos os middlewares no diretorio
 */

const files = require.context('.', false, /\.js$/)
const middlewares = []

files.keys().forEach(key => {
  // Ignora este proprio arquivo
  if (key === './index.js') return

  middlewares.push(files(key).default)
})

export default middlewares
