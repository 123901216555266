import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import middlewares from './middlewares'

Vue.use(VueRouter)

const router = new VueRouter({
  // mode: 'history',
  routes,
  base: process.env.BASE_URL,
  scrollBehavior: to => {
    if (to.hash) {
      return {
        selector: to.hash,
      }
    }

    return {
      x: 0,
      y: 0,
    }
  },
})

// Executa as middlewares
// eslint-disable-next-line array-callback-return
middlewares.map(middleware => {
  middleware(router)
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
