/**
 * Este arquivo importa todas as rotas no diretorio
 */
const files = require.context('.', true, /\.js$/)
const routes = []

function addRoute(route) {
  if (!route) return

  routes.push(route)
}

files.keys().forEach(key => {
  // Ignora este proprio arquivo
  if (key === './index.js') return

  // Pode estar recebendo um Array de rotas no caso 'nested'
  if (Array.isArray(files(key).default)) {
    files(key).default.forEach(route => addRoute(route))
  } else {
    addRoute(files(key).default)
  }
})
export default routes
