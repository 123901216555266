export default [{
  path: '/deposits',
  name: 'deposits',
  component: () => import('@/views/Deposits.vue'),
  meta: {
    page_slug: 'deposits',
    breadcrumb: [{
      page_slug: 'deposits',
      active: true,
    }],
  },
},
{
  path: '/dashboard',
  name: 'dashboard',
  component: () => import('@/views/Dashboard.vue'),
  meta: {
    page_slug: 'dashboard',
    breadcrumb: [{
      page_slug: 'dashboard',
      active: true,
    }],
  },
},
{
  path: '/profile',
  name: 'profile',
  component: () => import('@/views/Profile.vue'),
  meta: {
    page_slug: 'profile',
    breadcrumb: [{
      page_slug: 'profile',
      active: true,
    }],
  },
},
{
  path: '/withdraw',
  name: 'withdraw',
  component: () => import('@/views/Withdraw.vue'),
  meta: {
    page_slug: 'withdraws',
    breadcrumb: [{
      page_slug: 'withdraws',
      active: true,
    }],
  },
},
]
