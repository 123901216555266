/* eslint-disable no-shadow */
// initial state
const state = {
  token: localStorage.getItem('token') ? localStorage.getItem('token') : '',
  user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {},
}

// getters
const getters = {
  token: state => state.token,
  user: state => state.user,
}

// mutations
const mutations = {
  makeUser(state, data) {
    localStorage.setItem('user', JSON.stringify(data.user))
    localStorage.setItem('token', JSON.stringify(data.token))
    state.token = data.token
    state.user = data.user
  },
  makeLogout(state) {
    state.token = ''
    state.user = ''
  },
}

// exporta os atributos
export default {
  namespaced: true,
  state,
  getters,
  mutations,
}
